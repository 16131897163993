<template>
  <div class="top">
    <div class="title1">
      <img class="title-img" src="../../public/img/logo@3x.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.top {
  width: 100%;
  height: 8vh;
  background-color: white;
  font-size: 0.16rem;
  display: flex;

  align-items: center;
}
.title-img {
  width: 145px;
  height: 33px;
  margin-left: 115px;
}
img {
  /* width: 6vw; */
  height: 6vh;
  font-size: 0.16rem;
  margin-left: 8vw;
}
.title1 {
  width: 100vw;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  background-color: #6fb6b6;
  text-align: center;
}
</style>