import service from "@/util/service.js"

// 封装一个get请求
export function getlink(url, params) {
    return new Promise((resolve, reject) => {
        service.request({
            url,
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "token": localStorage.getItem("token") || "",
                
            },
            method: "get",
            params
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}